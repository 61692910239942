import React from 'react'
import { BrowserRouter, Routes,Route } from 'react-router-dom'
import Index from './components/Index'
import Vendor from './components/Vendor'
import New from './New'

function App() {
  return (
    <BrowserRouter>
    <Routes>
      {/* <Route path="/"  element={<Index/>} /> */}
      <Route path='/' element={<Vendor/>}/>
      <Route path='/:id' element={<Vendor/>}/>
      <Route path='/New'element={<New/>}/>
    </Routes>
    </BrowserRouter>
  )
}

export default App