import React,{useEffect, useState} from 'react'
import { Button, CardActionArea, CardActions, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import styled from '@emotion/styled';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LanguageIcon from '@mui/icons-material/Language';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MessageIcon from '@mui/icons-material/Message';
import { Link } from 'react-router-dom';
import { EditLocation } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
const bull = (
    
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >

    </Box>
);

function Vendor() {


    



    const [company,setCompany]=useState([]);
    const [product,setProduct]=useState([]);
    const [service,setService]=useState([]);
    
    const [name,setName]=useState('');
    const [email,setEmail]=useState('');
    const [contact,setContact]=useState('');
    const [message,setMessage]=useState('');
    const { id } = useParams();
    function handleSubmit(){
        if (!name || !email || !contact || !message) {
            alert("Please fill out all fields");
            return;
        }
        const namePattern = /^[a-zA-Z ]+$/;
        if (!namePattern.test(name)) {
            alert("Please enter a valid name (alphabets and spaces only)");
            return;
        }
    
        // Email pattern validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert("Please enter a valid email address");
            return;
        }
    
        // Contact pattern validation (assuming a 10-digit phone number)
        const contactPattern = /^\d{10}$/;
        if (!contactPattern.test(contact)) {
            alert("Please enter a valid 10-digit contact number");
            return;
        }
        const data={
            id:id,
            name:name,
            email:email,
            contact:contact,
            message:message
        };
        //console.log(data);
        axios.post("https://vcardadmin.bookmyfunctionhall.com/api/add_enquire",data)
        .then((res) => {
            const responseData = res.data;
            if(responseData.status==='success')
            {
                 window.location.reload();
                //alert('Enquire Added Successfully');
                //window.location.href=""
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }
   
   // console.log(id);
    function fetchCompany(){
    axios.get("https://vcardadmin.bookmyfunctionhall.com/api/getcompany/"+id)
        .then((res)=>{
            const data=res.data;
           // console.log(data);
            setCompany(data);
        })
    }
    function fetchProduct(){
        axios.get("https://vcardadmin.bookmyfunctionhall.com/api/getproduct/"+id)
            .then((res)=>{
                const data=res.data;
         // console.log(data);
                setProduct(data);
            })
        }
        function fetchService(){
            axios.get("https://vcardadmin.bookmyfunctionhall.com/api/getservice/"+id)
                .then((res)=>{
                    const data=res.data;
                   // console.log(data);
                    setService(data);
                })
            }
    useEffect(()=>{
        fetchCompany();
        fetchProduct();
        fetchService();
    },[])
    return (
        <>



            <Container className='Container' >
                <Box>

                    <Fab id='Fab1' variant="extended" >

                    </Fab>
                </Box>
                {
                        company.map((obj)=>{
                            
                            
                            return(
                <Box my={3}> 
                    <CardMedia sx={{ height: 100, width:100,marginLeft:15 }} image={`https://vcardadmin.bookmyfunctionhall.com/file/${obj.logo}`} title="green iguana"/>
                </Box>
                )
            })
                }
                {
                        company.map((obj)=>{
                            
                            
                            return(
                <Box id='Company-Name' >
                    <Typography variant="h5" gutterBottom>
                       {obj.company_name}
                    </Typography>

                </Box>
                )})}
                <div className='underline1' ></div>
                
                <Stack my={3} style={{}}>
                {
                        company.map((obj)=>{
                            const data = { __html: obj.address };
                            const addressWithoutTags = obj.address.replace(/(<([^>]+)>)/gi, "");
                            //console.log(addressWithoutTags);
                            return(
                    <Box sx={{ flexGrow: 1 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={3} md={3} lg={3} my={0}>
                                <a href={'tel:'+obj.contact}> <Button variant='outlined' size='small' id='button1' >
                                    <LocalPhoneRoundedIcon ></LocalPhoneRoundedIcon></Button></a>
                            </Grid>
                            <Grid item xs={9} md={9} lg={9}>
                                <p>{obj.contact}</p>
                            </Grid>
                            <Grid item xs={3} md={3} lg={3} my={0}>
                                <a href={'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox:'+obj.email}>
                                    <Button variant='outlined' size='small' id='button2'  >
                                        <MailOutlineRoundedIcon></MailOutlineRoundedIcon></Button></a>
                            </Grid>
                            <Grid item xs={9} md={9} lg={9}>
                                <p>{obj.email}</p>
                            </Grid>
                            {/* <Grid item xs={3} md={3} lg={3} my={0}>
                                <a href={'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox:$(obj.email}'}>
                                    <Button variant='outlined' size='small' id='button3' >
                                        <LanguageIcon></LanguageIcon></Button></a>
                            </Grid> */}
                            {/* <Grid item xs={9} md={9} lg={9}>
                                <p>www.electro.com</p>
                            </Grid> */}
                            <Grid item xs={3} md={3} lg={3} my={0}>
                                <a href={`https://map.google.com/?q=${encodeURIComponent(addressWithoutTags)}`}>
                                    <Button variant='outlined' size='small' id='button4'>
                                        <FmdGoodIcon></FmdGoodIcon></Button></a>
                            </Grid>
                            {/* <h1 dangerouslySetInnerHTML={data}/> */}
                            <Grid item xs={9} md={9} lg={9}>
                            <div dangerouslySetInnerHTML={data} />
                            </Grid>
                        </Grid>
                    </Box>

                    
                    )
                    
})

} 
<Button href={'tel:'+company[0]?.contact}  variant='contained' id='Save-Contact'>
    Save Contact
</Button>
                </Stack>
                {
                        company.map((obj)=>{
                          
                            const data = { __html: obj.about};
                            return(
                <CardContent className='card-content' >
                    <Typography variant="h6" component="div" color='#FF522F' id='text-heading'>
                        {obj.owner_name}
                    </Typography>
                    
                    <Typography variant="body2" id='textparagrap'>
                    <div dangerouslySetInnerHTML={data} />

                    </Typography>
                </CardContent>
                            )})
}
                 
                <br></br>
               

                <Box id='Company-Name' >
                    <Typography variant="h5" gutterBottom>
                        Product
                    </Typography>
                </Box>
                <div className='underline1' ></div>
                <br></br>
                
               <Box sx={{ flexGrow: 1 }} >
                    <Grid container spacing={2}>
                    {
                        product.map((objtest)=>{

                    const data = { __html: objtest.description };
                    return(
                        <Grid item xs={6} md={6} lg={6} my={0} mt={5}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{ height: 100,width:100 }}
                                    image={`https://vcardadmin.bookmyfunctionhall.com/file/${objtest.image}`}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {objtest.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <h6> <b>{objtest.price}</b></h6>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    <div dangerouslySetInnerHTML={data} />
                                    </Typography>
                                </CardContent>
                                
                            </Card>
                        </Grid>
                        )})
                    }
                        
                    </Grid>
                </Box> 
                         
                    <Box id='Company-Name' >
                    <Typography variant="h5" gutterBottom>
                        Service
                    </Typography>
                </Box>
                 <div className='underline1' ></div> 
                <br></br>
                <Box sx={{ flexGrow: 1 }} >
                    <Grid container spacing={2}>
                {
                        service.map((objtest)=>{

                    const data = { __html: objtest.description };
                    return(
                
                        <Grid item xs={6} md={6} lg={6} my={0} mt={5}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                   sx={{ height: 100,width:100 }}
                                    image={`https://vcardadmin.bookmyfunctionhall.com/file/${objtest.image}`}
                                    title="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {objtest.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <h6> <b>{objtest.price}</b></h6>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    <div dangerouslySetInnerHTML={data} />
                                    </Typography>
                                </CardContent>
                               
                            </Card>
                        </Grid>
                        
                  
                         )})
                    } 
                    </Grid>
                </Box> 
                
                <Box id='Company-Name' >
                    <Typography variant="h5" gutterBottom>
                        Enquire Form
                    </Typography>

                </Box>
                <Box sx={{ flexGrow: 1 }}>
                <div>
                <TextField 
                    style={{}}
                    id="filled-search"
                    label="Enter Name"
                    type="text"
                    //variant="filled"
                    //size="small" 
                    fullWidth
                    onChange={(e)=>setName(e.target.value)}
                    />
                    <TextField 
                    style={{marginTop:"5%"}}
                    id="filled-search"
                    label="Enter Email"
                    type="text"
                    //variant="filled"
                    //size="small" 
                    fullWidth
                    onChange={(e)=>setEmail(e.target.value)}
                    />
                    <TextField 
                    style={{marginTop:"5%"}}
                    id="filled-search"
                    label="Enter Contact"
                    type="text"
                    //variant="filled"
                    //size="small" 
                    fullWidth
                    onChange={(e)=>setContact(e.target.value)}
                    />
                    <TextField 
                    style={{marginTop:"5%"}}
                    id="filled-search"
                    label="Enter Message"
                    type="text"
                    multiline
                    rows={4}
                    //variant="filled"
                    //size="small" 
                    fullWidth
                    onChange={(e)=>setMessage(e.target.value)}
                    />
                    <Button  variant='contained' id='Save-Contact' color="success" style={{marginTop:"5%"}} onClick={()=>handleSubmit()}>
  Submit
</Button>
                </div>
                
                </Box>

                <Box id='Company-Name' >
                    <Typography variant="h5" gutterBottom>
                        Social Media
                    </Typography>

                </Box>
                <div className='underline1' ></div>
                {
                        company.map((obj)=>{
                          
                            var myObject = JSON.parse(obj.social_links);
                            return(
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} my={2}>
                        <Grid item xs={3} md={3} my={1}>
                            <a href={myObject.facebook}><Button variant='text' size='small' id='button-social1' >
                                <FacebookIcon></FacebookIcon>
                            </Button></a>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <a href={myObject.twitter}><Button variant='text' size='small' id='button-social2' >
                                <TwitterIcon></TwitterIcon>
                            </Button></a>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <a href={myObject.youtube}><Button variant='text' size='small' id='button-social3'>

                                <YouTubeIcon></YouTubeIcon>
                            </Button></a>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <a href={myObject.instagram}><Button variant='text' size='small' id='button-social4' >
                                <InstagramIcon></InstagramIcon>
                            </Button></a>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <a href={myObject.linked_in}><Button variant='text' size='small' id='button-social5' >
                                <LinkedInIcon></LinkedInIcon>
                            </Button></a>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <Button href={'https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox:'+company[0].email} variant='text' size='small' id='button-social6' >
                                <EmailIcon></EmailIcon>
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                            <Button href={'tel:'+company[0].contact} variant='text' size='small' id='button-social7' >
                                <WhatsAppIcon></WhatsAppIcon>
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3} my={1}>
                        <Button variant='text' size='small' id='button-social8'>
                            <MessageIcon />
                        </Button>
                        </Grid>
                    </Grid>
                </Box>
                            )})
}


            </Container>
        </>
    )
}

export default Vendor