import { RememberMe } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";

function New() {
    return (
        <div>
            {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Open modal for @mdo</button>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@fat">Open modal for @fat</button> */}
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">Login Form</button>

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header" style={{borderBottom:'none'}}>
                            <h1 className="modal-title" id="exampleModalLabel" style={{margin:'auto', fontSize:'35px'}}>Login In</h1>
                        </div>
                        <div className="modal-body " style={{margin:'auto'}}>
                            <form>
                                <div className="mb-3 ">
                                          <TextField id="standard-basic"  label="Email Id" variant="standard" />
                                </div>
                                <div className="mb-3 ">
                                <TextField id="standard-basic"   label="Password" variant="standard" />
                                <br></br>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Forgot Password" />


                                </div>
                            </form>
                        </div>
                        <div className="modal-footer"  style={{borderTop:'none',margin:'auto' ,marginTop:'-30px'}}>
                            <Button variant="contained" size="large" send message>
      Send Message
    </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default New;